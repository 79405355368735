import * as React from 'react';
import * as CarouselNavigatorStyle from './carousel-navigator.module.scss';

const CarouselNavigator = ({carouselContentSize, activeIndex, onNavigate}) => {
    return (
        <div className={`flex-jc`}>
            {
                Array.from({length: carouselContentSize || 1}, (_, index) =>
                    <div key={`carouselNavigator_${index + 1}`}>
                        <input
                            id={`carouselNavigator-${index + 1}`}
                            className={`${CarouselNavigatorStyle.carouselNavigator__radioBtn} w-0`}
                            name='carouselNavigator'
                            onChange={() => onNavigate(index)} type="radio"
                            checked={activeIndex === index}
                            hidden/>
                        <label
                            className={CarouselNavigatorStyle.carouselNavigator__action}
                            htmlFor={`carouselNavigator-${index + 1}`}
                        />
                    </div>
                )}
        </div>
    );
};

export default CarouselNavigator;
