import * as React from 'react'
import CardBody from '../body/card-body';
import CardFooter from '../footer/card-footer';
import CardHeader from '../header/card-header';

const Card = ({header, body, footer}) => {
    return (
        <div className={`font-s1-6r`}>
            {header}
            {body}
            {footer}
        </div>
    )
}
export default Object.assign(Card, {
    header: CardHeader,
    body: CardBody,
    footer: CardFooter
});