import * as React from 'react';
import * as CarouselStyle from './carousel.module.scss';
import CarouselContent from './content/carousel-content';
import CarouselNavigator from './navigator/carousel-navigator';

class Carousel extends React.Component {
    resizeObserver = null;

    state = {
        activeIndex: 0,
    };

    componentDidMount() {
        this.onWindowResize(() => {
            this.changeContent(this.state.activeIndex);
        })
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    onWindowResize(callback = () => {
    }) {
        this.resizeObserver = new ResizeObserver(() => {
            callback();
        });
        this.resizeObserver.observe(document.body);
    }

    changeContent = (index) => {
        this.setState({activeIndex: index});
        document.getElementById(`carouselTrack`).scrollTo({
            behavior: 'smooth',
            left: document.getElementById(`carousel-content-${index + 1}`).offsetLeft
        });
    }

    render() {
        const {children, className} = this.props;
        return (
            <div className={`${CarouselStyle?.carousel ?? ''} ${className ?? ''}`.trim() ?? null}>
                <ul id="carouselTrack" className={CarouselStyle.carousel__track}>
                    {
                        children?.length
                            ? children?.map((child, index) => {
                                if (child?.type?.name === CarouselContent.name) {
                                    return (
                                        <li
                                            key={child.toString() + index}
                                            id={`carousel-content-${index + 1}`}
                                            className={CarouselStyle?.carousel__contentWrapper}>
                                            {child}
                                        </li>);
                                }
                                return <li></li>;
                            })
                            : children
                    }
                </ul>
                <CarouselNavigator
                    onNavigate={this.changeContent}
                    activeIndex={this.state.activeIndex}
                    carouselContentSize={children?.length}/>
            </div>
        )
    };
}

export default Object.assign(Carousel, {
    content: CarouselContent,
});
