import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Card from '../../../components/card/container/card-container';
import Carousel from '../../../components/carousel/carousel';
import i18next from "i18next";

const query = graphql`
query MyQuery {
    allFile(filter: {extension: {eq: "png"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
    }
  }
`;

const CardFooter = ({ project }) => {
    return (
        <Card.footer>
            <h2 className="s2-5r mb-1r">
                {project.projectName}
            </h2>
            <p className="font-s1-3r light-text white-space__norm">
                {project.desc}
            </p>
        </Card.footer>
    );
}

const CardBody = ({ gatsbyImageData }) => {
    return (
        <Card.body className="text-center">
            <GatsbyImage className="border-rad-1r" alt="driving" image={gatsbyImageData} />
        </Card.body>
    );
}

const Projects = ({ data, projects }) => {
    return (
        projects.map((value, index) => {
            return <div key={value.toString() + index} className="col-1-of-3" >
                <Card
                    body={<CardBody gatsbyImageData={data.allFile.edges.find(edge => edge.node.relativePath === value.imageSrc)?.node?.childImageSharp?.gatsbyImageData} />}
                    footer={<CardFooter project={value} />}
                >
                </Card>
            </div>
        })
    );
}

const OurProjects = () => {
    const data = useStaticQuery(query);

    const projects = React.useMemo(()=>{
        return  i18next
            .t('projects.projects', { returnObjects: true })
            ?.reduce((p, c)=> {
                if (p[p.length - 1].length < 3){
                    p[p.length-1].push(c);
                } else {
                    p.push([c]);
                }
                return p;
            }, [[]]) ?? [];
    }, []);
    return (
        <section className='text-center font-s3r p-4r'>
            <h1 className='text-center'>{i18next.t('projects.title')}</h1>
            <Carousel>
                {
                    projects.map((content, index) => {
                        return <Carousel.content key={content.toString() + index}>
                            <div className="row w-80 p-1r">
                                <Projects projects={content} data={data} />
                            </div>
                        </Carousel.content>
                    })
                }
            </Carousel>
        </section>
    );
}

export default OurProjects;
